// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdSwitch from '../../../components/md-switch';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SelectEntities from '../../../components/select-entities';
import {ENTITY_TYPES, NEW_ID, ROLES} from '../../../constants/spec';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {VENDORS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {formatTechnologyLabel} from "../../../services/data-formatting.service";
import { useParams } from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {MdAsyncCreatableSelect} from "../../../components/md-select";
import {instanceAPI} from "../../../services/api.service";

//config
export const FORM_NAME = 'editVendor';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ VENDORS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase offset-bottom-6">
                                    <span>
                                        <RichHintTitle
                                            update={EDIT}
                                            name= {isNew ? translate('VENDORS$CREATE_VENDOR') : translate('VENDORS$EDIT_VENDOR')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, isNew ? `VENDORS_CREATE_TITLE` : 'VENDORS_EDIT_TITLE')}
                                        />
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON" />
                                </h2>
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
}

export default connect(
    state => ({expectAnswer: state.vendors.edit.expectAnswer, hints: state.vendors.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.vendors.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.vendors.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.vendors.edit.data,
        disabled: state.vendors.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.vendors.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // description
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        // industry
        if (!values.industry) {
            errors.industry = 'VENDORS$INDUSTRY_REQUIRED';
        }
        // site
        if (!values.site) {
            errors.site = 'ORGANIZATION$SITE_REQUIRED';
        }
        // country
        if (!values.country) {
            errors.country = 'ORGANIZATION$COUNTRY_REQUIRED';
        }
        // address 1
        if (!values.streetAddress1) {
            errors.streetAddress1 = 'ORGANIZATION$ADDRESS_1_REQUIRED';
        }
        // currency
        if (!values.currency) {
            errors.currency = 'ORGANIZATION$CURRENCY_REQUIRED';
        }
        // language
        if (!values.language) {
            errors.language = 'ORGANIZATION$LANGUAGE_REQUIRED';
        }
        // zip
        if (!values.zip) {
            errors.zip = 'ORGANIZATION$ZIP_REQUIRED';
        }
        // status
        if (!values.status) {
            errors.status = 'GLOBALS$STATUS_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues = {}, change, hints})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('VENDORS$ORGANIZATION_NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('VENDORS$ORGANIZATION_NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('VENDORS$ORGANIZATION_DESCRIPTION')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('VENDORS$ORGANIZATION_DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    name="owner"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    additionalFilters={{roles: [ROLES.VNDOWN]}}
                    getOptionLabel={option => get(option, 'fullName')}
                    label={(<strong> {translate('GLOBALS$OWNER')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={6}>
                <SelectEntities
                    name="parent"
                    disabled={disabled}
                    type={ENTITY_TYPES.VENDORS}
                    placeholder={translate('VENDORS$PARENT')}
                    label={(<strong> {translate('VENDORS$PARENT')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6}>
                <SelectEntities
                    name="industry"
                    disabled={disabled}
                    type={ENTITY_TYPES.INDUSTRIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('VENDORS$INDUSTRY')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4 offset-top-8">
            <Col xs={12} md={12} className="text-left">
                <strong className="required-asterisk">{translate('VENDORS$VENDOR_TYPE')} </strong>
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={3} className="text-center">
                <Field
                    color="primary"
                    fullWidth={false}
                    disabled={disabled}
                    name="isCloudVendor"
                    component={MdSwitch}
                    label={(<strong> {translate('VENDORS$CLOUD_VENDOR')} </strong>)}
                />
            </Col>
            <Col xs={12} md={3} className="text-center">
                <Field
                    color="primary"
                    fullWidth={false}
                    disabled={disabled}
                    component={MdSwitch}
                    name="isServiceVendor"
                    label={(<strong> {translate('VENDORS$SERVICE_VENDOR')} </strong>)}
                />
            </Col>
            <Col xs={12} md={3} className="text-center">
                <Field
                    color="primary"
                    fullWidth={false}
                    disabled={disabled}
                    component={MdSwitch}
                    name="isTechnologyVendor"
                    onChange={(e, value) => !value&&change('technologies', [])}
                    label={(<strong> {translate('VENDORS$TECHNOLOGY_VENDOR')}  </strong>)}
                />
            </Col>
            <Col xs={12} md={3} className="text-center">
                <Field
                    color="primary"
                    fullWidth={false}
                    disabled={disabled}
                    component={MdSwitch}
                    name="isSystemVendor"
                    label={(<strong> {translate('VENDORS$SYSTEM_VENDOR')} </strong>)}
                />
            </Col>
        </Row>
        {formValues.isTechnologyVendor && (
            <Row className="offset-bottom-4">
                {/*<Col xs={12} md={6}>*/}
                {/*    <SelectEntities*/}
                {/*        isMulti*/}
                {/*        disabled={disabled}*/}
                {/*        name="technologyCategories"*/}
                {/*        type={ENTITY_TYPES.TECH_CATEGORIES}*/}
                {/*        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}*/}
                {/*        label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TITLE')} </strong>)}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col xs={12} md={12}>
                    <SelectEntities
                        isMulti
                        name="technologies"
                        disabled={disabled}
                        type={ENTITY_TYPES.TECHNOLOGIES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        getOptionLabel={item => formatTechnologyLabel(item)}
                        label={(<strong> {translate('TECHNOLOGIES$TITLE')} </strong>)}
                    />
                </Col>
            </Row>
        )}
        {formValues.isSystemVendor && (
            <Row className="offset-bottom-4">
                <Col xs={12} md={12}>
                    <SelectEntities
                        isMulti
                        name="systems"
                        disabled={disabled}
                        type={ENTITY_TYPES.SYSTEMS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('SYSTEMS$TITLE')} </strong>)}
                    />
                </Col>
            </Row>
        )}
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    valueKey="id"
                    name="contract"
                    disabled={disabled}
                    isClearable={true}
                    type={ENTITY_TYPES.VENDOR_CONTRACTS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('VENDORS$DOCUMENT_LINK')} </strong>)}
                    getOptionLabel={option => option.name ? `${get(option, 'name')} (${get(option, 'number')})` : ""}

                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="site"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('ORGANIZATION$SITE')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('ORGANIZATION$SITE')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="tel"
                    name="phone"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('ORGANIZATION$PHONE_NUMBER')}
                    label={(<strong> {translate('ORGANIZATION$PHONE_NUMBER')} </strong>)}
                />
            </Col>
        </Row>
        <PositionConnected/>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="streetAddress1"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('ORGANIZATION$ADDRESS_1')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('ORGANIZATION$ADDRESS_1')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="streetAddress2"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('ORGANIZATION$ADDRESS_2')}
                    label={(<strong> {translate('ORGANIZATION$ADDRESS_2')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                <SelectEntities
                    valueKey="id"
                    name="currency"
                    disabled={disabled}
                    type={ENTITY_TYPES.CURRENCIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('ORGANIZATION$CURRENCY')} </strong>)}
                    getOptionLabel={option => `${get(option, 'name')} (${get(option, 'code')})`}
                />
            </Col>
            <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                <SelectEntities
                    name="language"
                    valueKey="id"
                    labelKey="name"
                    disabled={disabled}
                    type={ENTITY_TYPES.LANGUAGES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('ORGANIZATION$LANGUAGE')} </strong>)}
                />
            </Col>
            <Col xs={12} lg={4} className="offset-bottom-4">
                <Field
                    name="zip"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('ORGANIZATION$ZIP')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('ORGANIZATION$ZIP')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                <Field
                    name="taxId"
                    disabled={disabled}
                    component={MdInput}
                    placeholder={translate('ORGANIZATION$TAX_ID')}
                    label={(<strong> {translate('ORGANIZATION$TAX_ID')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                <Field
                    name="vatId"
                    disabled={disabled}
                    component={MdInput}
                    placeholder={translate('ORGANIZATION$VAT')}
                    label={(<strong> {translate('ORGANIZATION$VAT')} </strong>)}
                />
            </Col>
            <Col xs={12} lg={4} className="offset-bottom-4">
                <SelectEntities
                    name="status"
                    valueKey="id"
                    labelKey="name"
                    disabled={disabled}
                    type={ENTITY_TYPES.STATUSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$STATUS')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    multiline
                    name="notes"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NOTES')}
                    label={(<strong> {translate('GLOBALS$NOTES')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-6">
            <Col xs={12} md={3}>
                <Field
                    color="primary"
                    fullWidth={false}
                    disabled={disabled}
                    component={MdSwitch}
                    name="isInOfacList"
                    label={(<strong> {"Is in OFAC List?"} </strong>)}
                />
            </Col>
            <Col xs={12}>
                <Field
                    multiline
                    name="pastSecurityIncidents"
                    component={MdInput}
                    disabled={disabled}
                    placeholder="Past Security Incidents"
                    label={(<strong> {translate("Past Security Incidents")} </strong>)}
                />
            </Col>
        </Row>

        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? `BUTTON_VENDORS_CREATE` : 'BUTTON_VENDORS_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint = {findHint(hints, 'BUTTON_VENDORS_RESET')} />
                <CancelBtn onClick={cancel} hint = {findHint(hints, 'BUTTON_VENDORS_CANCEL')} />
            </Col>
        </Row>
    </form>
))));

const PositionConnected = withTranslation(connect(
    state => ({
        cities: state.vendors.edit.cities,
        states: state.vendors.edit.states,
        // values: state.form[FORM_NAME].values,
        values: getFormValues(FORM_NAME)(state),
        disabled: state.vendors.edit.expectAnswer,
    }),
    dispatch => ({
        setupCountry: countryId => dispatch({ type: EDIT.SETUP_COUNTRY.REQUEST, countryId }),
        setupState: ( countryId, stateId ) => dispatch({ type: EDIT.SETUP_STATE, countryId, stateId }),
        createCity: ( countryId, stateId, name ) => dispatch({ type: EDIT.CREATE_CITY, countryId, stateId, name }),
    })
)(({disabled, values, states, cities, setupCountry, setupState, createCity}) => (
    <Row>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <SelectEntities
                name="country"
                disabled={disabled}
                type={ENTITY_TYPES.COUNTRIES}
                placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                onChange={(e) => setupCountry(get(e, 'id', null))}
                required={true}
                label={(<strong className="required-asterisk"> {translate('ORGANIZATION$COUNTRY')} </strong>)}
            />
        </Col>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <SelectEntities
                name="state"
                defaultOptions={states}
                type={ENTITY_TYPES.STATES}
                placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                label={(<strong> {translate('ORGANIZATION$STATE')} </strong>)}
                disabled={disabled||!get(values, 'country.id', null)}
                additionalFilters={{countryId: get(values, 'country.id', null)}}
                onChange={(e) => setupState(get(values, 'country.id', null), get(e, 'id', null))}
            />
        </Col>
        <Col xs={12} lg={4} className="offset-bottom-4">
            <Field
                name="city"
                defaultOptions={cities}
                component={MdAsyncCreatableSelect}
                onCreateOption={name => createCity(get(values, 'country.id', null), get(values, 'state.id', null), name)}
                placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                label={(<strong> {translate('ORGANIZATION$CITY')} </strong>)}
                getNewOptionData={(inputValue, optionLabel)=>({id: inputValue, name: optionLabel, __isNew__: true})}
                additionalFilters={{stateId: get(values, 'state.id', null), countryId: get(values, 'country.id', null)}}
                disabled={disabled || !get(values, 'country.id', null) || (Boolean(states.length) && !get(values, 'state.id', null))}
                loadOptions={(name, done) => {
                    instanceAPI({
                        method: 'post',
                        url: '/cities/filter',
                        data: { page: 0, size: 6, filter: { name: name, countryId: get(values, 'country.id', null), stateId: get(values, 'state.id', null) } },
                    }).then(({items}) => done(items)).catch(done.bind(null, []));
                }}
            />
        </Col>

        {/*<Col xs={12} lg={4} className="offset-bottom-4">*/}
        {/*    <SelectEntities*/}
        {/*        name="city"*/}
        {/*        defaultOptions={cities}*/}
        {/*        type={ENTITY_TYPES.CITIES}*/}
        {/*        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}*/}
        {/*        label={(<strong> {translate('ORGANIZATION$CITY')} </strong>)}*/}
        {/*        additionalFilters={{stateId: get(values, 'state.id', null), countryId: get(values, 'country.id', null)}}*/}
        {/*        disabled={disabled||!get(values, 'country.id', null)||(Boolean(states.length)&&!get(values, 'state.id', null))}*/}
        {/*    />*/}
        {/*</Col>*/}
    </Row>
)));
