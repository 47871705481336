// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Person} from '@mui/icons-material';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {differenceBy, filter, get, reject, some, union} from 'lodash';
import {Paper} from '@mui/material';
import {useParams} from 'react-router-dom';

// local dependencies
import {EDIT} from './actions';
import MdInput from '../../components/md-input';
import {Link, PrimaryBtn, ResetBtn, SubmitBtn} from '../../components/md-button';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import SelectEntities from '../../components/select-entities';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumb';
import {ENTITY_TYPES, NEW_ID, ROLES} from '../../constants/spec';
import {translate, withTranslation} from '../../services/translate.service';
import {formatBusinessUnitLabel} from '../../services/data-formatting.service';
import {PROFILE_MAP} from '../../components/breadcrumbs/breadcrumbsMap';
import {PRIVATE_CHANGE_PASSWORD} from '../../constants/routes';
import InputImage from '../../components/input-image';
import {findHint, RichHintTitle} from '../../components/hints/hints';

/**
 * check user has 'vendor' roles
 */
let hasVendorRoles = roles => some(roles, role => role.name === ROLES.VENDOR || role.name === ROLES.VNDSUP);

/**
 * normalize 'roles' field
 */
let normalizeRoles = (values, prevValues) => {
    let diff1 = differenceBy(values, prevValues, 'id');
    let diff2 = differenceBy(prevValues, values, 'id');
    let updated = union(diff1, diff2), newValues = values || prevValues;
    // NOTE remove other roles if 'vendor' roles has selected
    if (hasVendorRoles(updated)) {
        newValues = filter(newValues, role => role.name === ROLES.VENDOR || role.name === ROLES.VNDSUP);
    }
    // NOTE remove 'vendor' roles if 'common' roles has selected
    if (hasVendorRoles(values) && !hasVendorRoles(updated)) {
        newValues = reject(newValues, role => role.name === ROLES.VENDOR || role.name === ROLES.VNDSUP);
    }
    return newValues;
};

const Edit = (props, {expectAnswer}) => {
    const {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, [])
    ;
    let isNew = id === NEW_ID;
    let {hints} = props;
// componentDidMount() { this.props.initialize( this.props.match.params.id ); }
// componentWillUnmount() { this.props.clear(); }

// let {expectAnswer, match} = this.props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={PROFILE_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase offset-bottom-8 text-center">
                                <Person fontSize="large" className="align-middle"/>&nbsp;
                                <span className="align-middle">
                                     <RichHintTitle update={EDIT}
                                                    name={translate('PROFILE$TITLE')}
                                                    expectAnswer={expectAnswer}
                                                    data={findHint(hints, 'PROFILE_TITLE')}
                                     />
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({expectAnswer: state.profile.expectAnswer, hints: state.profile.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.profile.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.profile.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.profile.data,
        hints: state.profile.hintsData,
        disabled: state.profile.expectAnswer,
        formValues: getFormValues('editProfile')(state)
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        resetPassword: () => dispatch({type: EDIT.RESET_PASSWORD}),
        update: (formData) => {
            console.log(formData);
            dispatch({type: EDIT.UPDATE, ...formData});
        },
    })
)(reduxForm({
    form: 'editProfile',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // first name
        if (!values.firstName) {
            errors.firstName = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})(({
    handleSubmit,
    hints,
    invalid,
    pristine,
    disabled,
    update,
    reset,
    isNew,
    resetPassword,
    formValues,
    initialValues,
    change
}) => {
    let isVendorUser = hasVendorRoles(get(formValues, 'roles', []));
    return (<form autoComplete="off" name="editProfile" onSubmit={handleSubmit(update)}>
        <Row>
            <Col sm={3} className="user-profile-logo">
                <Field
                    required
                    name="profilePicture"
                    placeholder="Profile Picture"
                    url={formValues && formValues.profilePicture ? formValues.profilePicture.url : ''}
                    component={InputImage}
                    className="form-control"
                    maxWeight={2}
                    onChange={(uploadResult) => {
                        formValues.profilePicture = uploadResult.logoDocument;
                        update(formValues);
                    }}
                />
            </Col>
            <Col sm={9}>
                <Row>
                    <Col xs={12} className="offset-bottom-4">
                        <Field
                            name="firstName"
                            component={MdInput}
                            disabled={disabled}
                            placeholder={translate('USERS$FIRST_NAME')}
                            required={true}
                            label={(<strong className="required-asterisk"> {translate('USERS$FIRST_NAME')} </strong>)}
                        />
                    </Col>
                    <Col xs={12} className="offset-bottom-4">
                        <Field
                            name="lastName"
                            component={MdInput}
                            disabled={disabled}
                            placeholder={translate('USERS$LAST_NAME')}
                            label={(<strong> {translate('USERS$LAST_NAME')} </strong>)}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="email"
                    component={MdInput}
                    disabled={true}
                    placeholder={translate('USERS$EMAIL')}
                    label={(<strong> {translate('USERS$EMAIL')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    readOnly
                    type="text"
                    component={MdInput}
                    name="organizationName"
                    disabled={true}
                    label={(<strong>{translate('ORGANIZATION$NAMEFIELD')}</strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="tel"
                    name="corporatePhone"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('USERS$CORPORATE_PHONE')}
                    label={(<strong> {translate('USERS$CORPORATE_PHONE')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="tel"
                    name="mobilePhone"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('USERS$MOBILE_PHONE')}
                    label={(<strong> {translate('USERS$MOBILE_PHONE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    name="roles"
                    isMulti={true}
                    disabled={true}
                    type={ENTITY_TYPES.ROLES}
                    normalize={normalizeRoles}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('USERS$ROLES')} </strong>)}
                    onChange={(e, roles) => !hasVendorRoles(roles) && change('vendors', [])}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="title"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('USERS$TITLE_FIELD')}
                    label={(<strong> {translate('USERS$TITLE_FIELD')} </strong>)}
                />
            </Col>
        </Row>
        {isVendorUser && (<Row className="offset-bottom-4"><Col xs={12}>
            <SelectEntities
                name="vendors"
                isMulti={true}
                disabled={disabled}
                type={ENTITY_TYPES.VENDORS}
                placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                label={(<strong> {translate('VENDORS$TITLE')} </strong>)}
            />
        </Col> </Row>)}
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <SelectEntities
                    name="businessUnit"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="text-center offset-bottom-4">
                <Link Btn={PrimaryBtn} placement="left" to={PRIVATE_CHANGE_PASSWORD.LINK()}
                      hint={findHint(hints, 'BUTTON_PROFILE_CHANGE_PASSWORD')} >
                    {translate('AUTHORIZATION$CHANGE_PASSWORD')}&nbsp;
                    <Preloader expectAnswer={disabled} type="ICON"> </Preloader>
                </Link>
            </Col>
            <Col xs={12} md={6} className="text-center offset-bottom-4">
                <PrimaryBtn
                    onClick={resetPassword}
                    disabled={disabled || isNew}
                    className="md-btn md-btn-danger"
                    hint={findHint(hints, 'BUTTON_PROFILE_RESET_PASSWORD')}
                >
                    {translate('FORGOT_PASSWORD$RESET_PASSWORD')}&nbsp;
                    <Preloader expectAnswer={disabled} type="ICON"> </Preloader>
                </PrimaryBtn>
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right  offset-top-6">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                           hint={findHint(hints, 'BUTTON_PROFILE_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                          hint={findHint(hints, 'BUTTON_PROFILE_RESET')}/>
            </Col>
        </Row>
    </form>);
})));